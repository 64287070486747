.container {
    position: relative;
    aspect-ratio: 300 / 200;

    max-height: 300px;
    border-radius: 8px;
    overflow: hidden;
    border: 2px dashed transparent;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    input[type="file"] {
        cursor: pointer;
        display: block;
        height: 100%;
        left: 0;
        opacity: 0 !important;
        position: absolute;
        top: 0;
        width: 100%;
    }
    &.drag_over {
        border: 2px dashed #de2c48;
    }
    .deleteButton {
        position: absolute;
        top: 5px;
        right: 5px;
        margin: 0px;
        padding: 0px;
        width: 40px;
        height: 40px;
    }
}

.emptyContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    aspect-ratio: auto 300 / 200;
    max-height: 300px;
    border-radius: 8px;
    overflow: hidden;
    border: 2px dashed #de2c48;
    background-color: #fbeaec;
    flex-grow: 1;
    margin: 0px 10px 10px 0px;
    .image {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    input[type="file"] {
        cursor: pointer;
        display: block;
        height: 100%;
        left: 0;
        opacity: 0 !important;
        position: absolute;
        top: 0;
        width: 100%;
    }
    &.drag_over {
        border: 2px dashed #de2c48;
        background-color: #f8d5da;
    }
    .deleteButton {
        position: absolute;
        top: 5px;
        right: 5px;
        margin: 0px;
        padding: 0px;
        width: 40px;
        height: 40px;
    }
}
