.container {
    .imagesContainer {
        display: flex;
        flex-direction: row;
        @media (max-width: 600px) {
            flex-direction: column;
        }
    }
    .mainImageContainer {
        flex-basis: 600px;

        @media (max-width: 600px) {
            flex-basis: 0px;
        }
    }
}
