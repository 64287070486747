.fixed-plugin li > a,
.fixed-plugin .badge {
    transition: all 0.34s;
    -webkit-transition: all 0.34s;
    -moz-transition: all 0.34s;
}

.fixed-plugin {
    position: fixed;
    top: 180px;
    right: 0;
    width: 64px;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1031;
    border-radius: 8px 0 0 8px;
    text-align: center;

    &.fixed-plugin-rtl {
        right: auto;
        left: 0;
        border-radius: 0 8px 8px 0;
        direction: ltr;
        .dropdown.show .dropdown-menu {
            right: auto;
            left: 80px;
            &:before {
                border-left: none;
                border-right: 16px solid rgba(0, 0, 0, 0.2);
                left: -17px;
                right: auto;
            }
            &:after {
                border-left: none;
                border-right: 16px solid #ffffff;
                left: -16px;
                right: auto;
            }
        }
    }

    .dropdown-menu {
        text-align: center;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        display: none;
        float: left;
        min-width: 160px;
        padding: 5px 0;
        margin: 2px 0 0;
        font-size: 14px;
        list-style: none;
        background-color: #fff;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        border: 1px solid #ccc;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    }

    .dropdown-menu li > a {
        font-size: 13px;
        padding: 10px 20px;
        margin: 0 5px;
        border-radius: 2px;
        -webkit-transition: all 150ms linear;
        -moz-transition: all 150ms linear;
        -o-transition: all 150ms linear;
        -ms-transition: all 150ms linear;
        transition: all 150ms linear;
    }
    .dropdown-menu > li > a {
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: 400;
        line-height: 1.42857143;
        color: #333;
        white-space: nowrap;
    }
}

.fixed-plugin .fa-cog {
    color: #ffffff;
    padding: 10px;
    border-radius: 0 0 6px 6px;
    width: auto;
}

.fixed-plugin .dropdown-menu {
    right: 80px;
    left: auto;
    width: 290px;
    border-radius: 10px;
    padding: 0 10px;
}

.fixed-plugin .dropdown-menu:after,
.fixed-plugin .dropdown-menu:before {
    right: 10px;
    margin-left: auto;
    left: auto;
}

.fixed-plugin .fa-circle-thin {
    color: #ffffff;
}

.fixed-plugin .active .fa-circle-thin {
    color: #00bbff;
}

.fixed-plugin .dropdown-menu > .active > a,
.fixed-plugin .dropdown-menu > .active > a:hover,
.fixed-plugin .dropdown-menu > .active > a:focus {
    color: #777777;
    text-align: center;
}

.fixed-plugin img {
    border-radius: 0;
    width: 100%;
    height: 100px;
    margin: 0 auto;
}

.fixed-plugin .dropdown-menu li > a:hover,
.fixed-plugin .dropdown-menu li > a:focus {
    box-shadow: none;
}

.fixed-plugin .badge {
    border: 3px solid #ffffff;
    border-radius: 50%;
    cursor: pointer;
    display: inline-block;
    height: 23px;
    margin-right: 5px;
    position: relative;
    width: 23px;
}

.fixed-plugin .badge.active,
.fixed-plugin .badge:hover {
    border-color: #00bbff;
}

.fixed-plugin .badge-blue {
    background-color: #00bcd4;
}

.fixed-plugin .badge-green {
    background-color: #4caf50;
}

.fixed-plugin .badge-orange {
    background-color: #ff9800;
}

.fixed-plugin .badge-purple {
    background-color: #9c27b0;
}

.fixed-plugin .badge-red {
    background-color: #f44336;
}

.fixed-plugin .badge-rose {
    background-color: #de2c48;
}

.fixed-plugin .badge-black {
    background-color: #000;
}

.fixed-plugin .badge-white {
    background-color: rgba(200, 200, 200, 0.2);
}

.fixed-plugin h5 {
    font-size: 14px;
    margin: 10px;
}

.fixed-plugin .dropdown-menu li {
    display: block;
    padding: 5px 2px;
    width: 25%;
    float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container {
    width: 100%;
    height: 50px;
    min-height: inherit;
    position: relative;
}

.fixed-plugin li.button-container div {
    margin-bottom: 5px;
}

.fixed-plugin #sharrreTitle {
    text-align: center;
    padding: 10px 0;
    height: 50px;
}

.fixed-plugin li.header-title {
    height: 30px;
    line-height: 25px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
    float: left;
    display: inline-block;
    margin-bottom: 0;
    font-size: 1em;
    color: #3c4858;
    margin-top: 4px;
    &.switch-label + span {
        position: absolute;
        right: 0;
        top: -4px;
    }
}

.fixed-plugin .adjustments-line a .badge-colors {
    position: relative;
    top: -2px;
}

.fixed-plugin .adjustments-line .togglebutton {
    float: right;
}

.fixed-plugin .adjustments-line .togglebutton .toggle {
    margin-right: 0;
}

.fixed-plugin .dropdown-menu > li.adjustments-line > a {
    padding-right: 0;
    padding-left: 0;
    border-bottom: 1px solid #ddd;
    border-radius: 0;
    margin: 0;
    height: stretch;
}

.fixed-plugin .dropdown-menu > li > a.img-holder {
    font-size: 16px;
    text-align: center;
    border-radius: 10px;
    background-color: #fff;
    border: 3px solid #fff;
    padding-left: 0;
    padding-right: 0;
    opacity: 1;
    cursor: pointer;
    max-height: 100px;
    overflow: hidden;
    padding: 0;
}

.fixed-plugin .dropdown-menu > li > a.switch-trigger:hover,
.fixed-plugin .dropdown-menu > li > a.switch-trigger:focus {
    background-color: transparent;
}

.fixed-plugin .dropdown-menu > li:hover > a.img-holder,
.fixed-plugin .dropdown-menu > li:focus > a.img-holder {
    border-color: rgba(0, 187, 255, 0.53);
}

.fixed-plugin .dropdown-menu > .active > a.img-holder,
.fixed-plugin .dropdown-menu > .active > a.img-holder {
    border-color: #00bbff;
    background-color: #ffffff;
}

.fixed-plugin .dropdown-menu > li > a img {
    margin-top: auto;
}

.fixed-plugin .btn-social {
    width: 50%;
    display: block;
    width: 48%;
    float: left;
    font-weight: 600;
}

.fixed-plugin .btn-social i {
    margin-right: 5px;
}

.fixed-plugin .btn-social:first-child {
    margin-right: 2%;
}

.fixed-plugin .dropdown .dropdown-menu {
    -webkit-transform: translateY(-15%);
    -moz-transform: translateY(-15%);
    -o-transform: translateY(-15%);
    -ms-transform: translateY(-15%);
    transform: translateY(-15%);
    top: 8px;
    opacity: 0;
    transform-origin: 0 0;
    display: none;
}

.fixed-plugin .dropdown.show .dropdown-menu {
    opacity: 1;
    -webkit-transform: translateY(-13%);
    -moz-transform: translateY(-13%);
    -o-transform: translateY(-13%);
    -ms-transform: translateY(-13%);
    transform: translateY(-13%);
    transform-origin: 0 0;
    display: block;
}

.fixed-plugin .dropdown-menu:before,
.fixed-plugin .dropdown-menu:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 84px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
}

.fixed-plugin .dropdown-menu:before {
    border-bottom: 16px solid rgba(0, 0, 0, 0);
    border-left: 16px solid rgba(0, 0, 0, 0.2);
    border-top: 16px solid rgba(0, 0, 0, 0);
    right: -17px;
}

.fixed-plugin .dropdown-menu:after {
    border-bottom: 16px solid rgba(0, 0, 0, 0);
    border-left: 16px solid #ffffff;
    border-top: 16px solid rgba(0, 0, 0, 0);
    right: -16px;
}

.fixed-plugin #sharrreTitle {
    text-align: center;
    padding: 10px 0;
}

.fixed-plugin .clearfix {
    &::before,
    &::after {
        display: table;
        content: " ";
    }
    &::after {
        clear: both;
    }
}

@media (max-width: 991px) {
    .fixed-plugin .dropdown-menu {
        right: 81px;
        width: 250px;
    }
    .fixed-plugin .adjustments-line .switch {
        float: right;
        margin: 0 0px;
    }
    .fixed-plugin li.header-title {
        height: 40px;
    }
    .fixed-plugin .dropdown .dropdown-menu {
        top: 10px;
    }
    .fixed-plugin .dropdown-menu:after,
    .fixed-plugin .dropdown-menu:before {
        top: 96px;
    }
    .fixed-plugin .dropdown-menu > li > a.img-holder {
        max-height: 75px;
    }
    .fixed-plugin {
        top: 80px;
    }
}
