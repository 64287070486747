.hoverContainer {
    position: relative;
    display: inline-block;
}

.hoverElement {
    display: inline-block;
    cursor: pointer;
    align-self: center;
    padding: 0;
    margin: 0;
}

.infoBox {
    display: none;
    position: absolute;
    top: 20px;
    padding: 10px;
    background-color: #fbeaec;
    border: 1px solid #f2abb6;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 10;
    font-size: 0.8rem;
    line-height: 1rem;
    width: 300px;
}

.hoverContainer:hover .infoBox {
    display: block;
}
