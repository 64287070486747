@font-face {
  font-family: 'Livvic';
  src: url('../../fonts/Livvic-Light.eot');
  src: url('../../fonts/Livvic-Light.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Livvic-Light.woff') format('woff'),
  url('../../fonts/Livvic-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Livvic';
  src: url('../../fonts/Livvic-Light.eot');
  src: url('../../fonts/Livvic-Light.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Livvic-Light.woff') format('woff'),
  url('../../fonts/Livvic-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Livvic';
  src: url('../../fonts/Livvic-Regular.eot');
  src: url('../../fonts/Livvic-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Livvic-Regular.woff') format('woff'),
  url('../../fonts/Livvic-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Livvic';
  src: url('../../fonts/Livvic-Medium.eot');
  src: url('../../fonts/Livvic-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Livvic-Medium.woff') format('woff'),
  url('../../fonts/Livvic-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Livvic';
  src: url('../../fonts/Livvic-MediumItalic.eot');
  src: url('../../fonts/Livvic-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Livvic-MediumItalic.woff') format('woff'),
  url('../../fonts/Livvic-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Livvic';
  src: url('../../fonts/Livvic-Bold.eot');
  src: url('../../fonts/Livvic-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Livvic-Bold.woff') format('woff'),
  url('../../fonts/Livvic-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Livvic';
  src: url('../../fonts/Livvic-BoldItalic.eot');
  src: url('../../fonts/Livvic-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../../fonts/Livvic-BoldItalic.woff') format('woff'),
  url('../../fonts/Livvic-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}